.sidebar {
  background-color: #333;
  color: #fff;
}
.sidebar__container {
  padding: 20px;
}
.sidebar__nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar__nav-list-item .list_item_wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
}
.sidebar__nav-list-item .list_item_wrapper:hover {
  background-color: #1a1a1a;
  cursor: pointer;
}
.sidebar__nav-list-item .list_item_wrapper .list_item_label {
  margin-left: 10px;
  font-size: 0.7rem;
  white-space: nowrap;
}/*# sourceMappingURL=sidebar.css.map */