.register {
  background-color: #f4f4f4;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register .register_container {
  width: 100%;
  max-width: 400px;
}
@media (max-width: 480px) {
  .register .register_container {
    margin: -30% 10px 0 10px;
  }
}
.register .register_container .register_wrapper {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}/*# sourceMappingURL=register.css.map */