$sidebar-background-color: #333;
$sidebar-text-color: #fff;
$sidebar-list-item-padding: 10px;

.sidebar {
  background-color: $sidebar-background-color;
  color: $sidebar-text-color;
  &__container {
    padding: 20px;
  }
  &__nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__nav-list-item {
    .list_item_wrapper {
      display: flex;
      align-items: center;
      padding: $sidebar-list-item-padding;
      &:hover {
        background-color: darken($sidebar-background-color, 10%);
        cursor: pointer;
      }
      .list_item_label {
        margin-left: 10px;
        font-size: 0.7rem;
        white-space: nowrap;
      }
    }
  }
}
