@keyframes slideAndCollapse {
  0% {
    transform: translateX(-100%);
    width: 100%;
  }
  50% {
    transform: translateX(0);
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.slide-and-collapse {
  animation: slideAndCollapse 0.6s forwards;
}
