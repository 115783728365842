@import url("https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&display=swap");
:root {
  --text-color: #000;
  --text-color-inv: #fff;
}

* {
  padding: 0;
  margin: 0;
  border-style: border-box;
}

html,
body {
  font-family: "Radio Canada Big", sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

table {
  margin: 20px 0;
}

table,
th,
tr,
td {
  border: 1px solid #000;
  border-collapse: collapse;
}

td {
  padding: 5px;
}

input {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: textfield;
          appearance: textfield;
  -moz-appearance: textfield;
}

.hide {
  display: none !important;
}

html,
body {
  scroll-behavior: smooth;
  font-family: "Radio Canada Big", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  direction: ltr;
}

.no-data-message {
  text-align: center;
  font-size: 16px;
  color: #888;
  padding: 20px;
}

/**
 * ==============================================
 * dot-flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 auto;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffffff;
  }
  50%, 100% {
    background-color: rgba(255, 243, 243, 0.2);
  }
}
.spinner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 10px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=globals.css.map */