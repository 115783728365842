$register-background-color: #f4f4f4;
$register-box-background-color: #fff;
$register-text-color: #333;
$register-form-group-margin-bottom: 20px;
$register-input-padding: 10px;
$register-btn-background-color: #007bff;
$register-btn-text-color: #fff;
$register-btn-hover-color: #0056b3;

.register {
  background-color: $register-background-color;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .register_container {
    width: 100%;
    max-width: 400px;
    @media (max-width: 480px) {
      margin: -30% 10px 0 10px;
    }
    .register_wrapper {
      padding: 20px;
      background-color: $register-box-background-color;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
}
