.blogs-section {
  min-height: 70vh;
  width: 100%;
  margin-top: 20px;
}

.blogs-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-top: 50px;
}

.blogs-container-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.create-blog-title {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  font-size: 21px;
}

.title-blogs {
  font-weight: 600;
  font-size: 18px;
}

.input-title,
.input-file,
.textarea-description {
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.input-title:focus,
.input-file:focus,
.textarea-description:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.textarea-description {
  height: 150px;
  resize: vertical;
}

.create-blog-button {
  width: 200px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-blog-button:hover {
  background-color: #0056b3;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 568px) {
  .blogs-container {
    width: 100%;
  }

  .input-title,
  .input-file,
  .textarea-description {
    width: 350px;
  }
}
