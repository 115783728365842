.edit-blogs-section {
  width: 100%;
  min-height: 100vh;
}

.edit-blog-section-inner {
  width: 90%;
}

.edit-blog-section-inner .p-1 {
  margin: top 30px;
  text-align: center;
  font-size: 31px;
  font-weight: bold;
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-blogs {
  text-align: center;
  font-weight: bold;
}

.edit-blogs-container {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  margin-top: 20px;
  margin-left: 70px;
  /* margin-right:auto; */
}

.edit-blog-section-main {
  width: calc(50% - 10px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.edit-blog-image {
  position: relative;
  width: 50%;
}
.edit-blog-image-class {
  width: 350px;
  height: 300px;
  object-fit: cover;
  margin-top: 30px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.image-overlay {
  position: absolute;
  /* top:10px; */
  bottom: 10px;
  z-index: 2;
  right: 10px;
  display: flex;
  gap: 10px;
}

.edit-icon {
  width: 30px;
  height: 30px;
  background: linear-gradient(135deg, #3399ff, #66ccff);
  color: white;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}

.delete-icon {
  width: 30px;
  height: 30px;
  /* background: linear-gradient(135deg, #3399ff, #66ccff); */
  background-color: white;
  color: red;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}

.blog-content {
  width: 50%;
  margin-left: 10px;
  padding: 0 10px;
  max-height: 300px;
  overflow-y: auto;
}

.blog-title {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.blog-description {
  font-size: 15px;
  text-align: center;
  color: grey;
  margin-top: 20px;
  hyphens: auto;
  word-wrap: break-word;
}

@media screen and (max-width: 568px) {
  .edit-blog-section-inner {
    width: 100%;
  }

  .edit-blogs-container {
    flex-direction: column;
    gap: 10px;
    /* margin-left: auto;
        justify-content: center;
        align-items:center;
        margin-right:auto; */
    margin-left: 30px;
  }
  .edit-blog-section-main {
    width: 100%;
    flex-direction: column;
  }

  .edit-blog-image {
    width: 100%;
  }

  .blog-content {
    width: 100%;
  }

  .image-overlay {
    right: 100px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 912px) {
  .edit-blog-section-inner {
    width: 100%;
  }

  .edit-blogs-container {
    flex-direction: column;
    gap: 10px;
    /* margin-left: auto;
        justify-content: center;
        align-items:center;
        margin-right:auto; */
    margin-left: 60px;
  }
  .edit-blog-section-main {
    width: 100%;
    flex-direction: column;
  }

  .edit-blog-image {
    width: 100%;
  }

  .blog-content {
    width: 100%;
  }

  .image-overlay {
    right: 320px;
  }
}

/* DELETE MODAL */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.yes-button {
  background-color: #007bff;
  color: white;
}

.no-button {
  background-color: #dc3545;
  color: white;
}

.modal-button:hover {
  opacity: 0.8;
}

.modal-input {
  margin-bottom: 15px;
  text-align: left;
}

.modal-input label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.modal-input input,
.modal-input textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
